.week {
  position: sticky;
  top: 0;
  z-index: 5;
  margin-top: 20px;
  border: 2px solid whitesmoke;
  padding-block: 10px;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  --stripe: #18293b;
  --bg: #142333;
  background: linear-gradient(135deg, var(--bg) 25%, transparent 25%) -50px 0,
    linear-gradient(225deg, var(--bg) 25%, transparent 25%) -50px 0,
    linear-gradient(315deg, var(--bg) 25%, transparent 25%),
    linear-gradient(45deg, var(--bg) 25%, transparent 25%);
  background-size: 100px 100px;
  background-color: var(--stripe);
  height: 100%;
}

.week > h1 {
  font-weight: 600;
}
