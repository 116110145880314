.labels i {
  font-size: 0.75rem;
  margin-right: 5px;
}

.labels h3 {
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 1.5px;
}

.labels {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0.75rem;
  border: 3px solid #ccc;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 1rem;
}

@media (max-width: 800px) {
  .labels {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .labels {
    grid-template-columns: repeat(1, 1fr);
  }
}
