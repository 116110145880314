:root {
  --cs: #ea2027;
  --sc: #78d237;
  --is: #ffd246;
  --sys: #2d73f5;
}

.course_title {
  position: relative;
  background: whitesmoke;
  color: #333;
  font-size: clamp(1rem, 2vw, 2rem);
  font-weight: 700;
  text-align: left;
  padding: 15px;
  padding-left: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.course_title:hover {
  background: #ddd;
}

.cs,
.sc,
.is,
.sys {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  bottom: 27%;
}

.cs {
  right: 140px;
  background-color: var(--cs);
}

.sc {
  right: 100px;
  background-color: var(--sc);
}

.is {
  right: 60px;
  background-color: var(--is);
}

.sys {
  right: 20px;
  background-color: var(--sys);
}

@media (max-width: 800px) {
  .course_title {
    padding: 10px;
    margin-bottom: 10px;
  }
  .warp {
    padding-right: 100px;
  }
  .cs,
  .sc,
  .is,
  .sys {
    width: 20px;
    height: 20px;
    bottom: 13px;
  }
  .cs {
    right: 82px;
  }
  .sc {
    right: 58px;
  }
  .is {
    right: 34px;
  }
  .sys {
    right: 10px;
  }
}
