.issues {
  text-align: center;
  color: white;
  font-weight: 500;
  font-size: clamp(12px, 3vw, 30px);
  margin-top: 1rem;
}

.issues a {
  font-weight: 700;
  color: white;
}
