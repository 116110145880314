.material {
    position: relative;
    background-color: whitesmoke;
    padding: 10px;
    padding-left: 20px;
    text-align: left;
    font-size: clamp(1.2rem, 3vw, 1.4rem);
    color: #0078e3;
}

.material>i {
    font-size: clamp(1.2rem, 3vw, 1.4rem);
    margin-right: 10px;
    width: 25px;
    text-align: center;
}


/* Customize the label (the check_container) */

.check_container {
    position: relative;
    float: right;
    margin-top: 4px;
    margin-right: 10px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Hide the browser's default checkbox */

.check_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    right: 0;
    height: 25px;
    width: 25px;
    background-color: #ddd;
}


/* On mouse-over, add a grey background color */

.check_container:hover input~.checkmark {
    background-color: #bbb;
}


/* When the checkbox is checked, add a blue background */

.check_container input:checked~.checkmark {
    background-color: #0078e3;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.check_container input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.check_container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media screen and (max-width: 768px) {
    .check_container {
        margin-top: 2px;
    }
}