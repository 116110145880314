.announcement {
  background-color: #e6e6e6;
  margin-top: 10px;
  padding: 5px;
}

.announcement > h1 {
  font-size: 1.3rem;
  font-weight: 300;
  padding: 5px;
}

.announcement > p {
  font-size: 0.8rem;
}

.announcement a {
  color: #0078e3;
}
