#not_found>h1 {
    margin-top: 100px;
    font-size: clamp(2rem, 6vw, 4rem);
}

#not_found>p {
    margin-top: 30px;
    font-size: clamp(1rem, 4vw, 1.8rem);
}

#not_found>p>code {
    background: rgba(255, 255, 255, 0.2);
    padding: 5px 10px;
    border-radius: 10px;
    letter-spacing: 2px;
}

#not_found>p>a {
    background: rgb(255, 255, 255);
    padding: 0.4rem 0.8rem;
    border-radius: 10px;
    text-decoration: none;
    line-height: 60px;
    color: #222;
}